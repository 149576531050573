import jQuery from 'jquery';
import 'bootstrap';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import WebFont from 'webfontloader';

import 'owl.carousel';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add(fab, fas, far, fal);

// Vue Stuff
import Vue from "vue/dist/vue.min.js";
import VueRouter from "vue-router";

window.Event = new Vue();

window.Vue = Vue;

Vue.use(VueRouter);

import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);

dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Droid Sans', 'Source Sans Pro', 'Nunito Sans:400,700,900', ]
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home
    /** About Us page, note the change from about-us to aboutUs. */
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());