import gsap from "gsap";

export default {
    init() {
        console.log('common');
    },
    finalize() {

        (function($) {
            'use strict';

            $(document).ready(function() {
                eventListener();
                // navigationScroll();
                $('.burger-menu-container').on('click', mobileMenuToggle);

                // $('.nav-tabs li a').click(function (e) {
                //     e.preventDefault();
                //     $(this).tab('show');
                //     $('.tab-content > .tab-pane.active').jScrollPane();
                // });

                $('.owl-carousel.client-testimonial-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<i class="blog-button fas fa-chevron-left"></i>',
                        '<i class="blog-button fas fa-chevron-right"></i>'
                    ],
                    items: 1
                });

                $('.owl-carousel.client-video-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<i class="blog-button fas fa-chevron-left"></i>',
                        '<i class="blog-button fas fa-chevron-right"></i>'
                    ],
                    items: 2,
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplaySpeed: 1000,
                    responsive : {
                        // breakpoint from 0 up
                        0 : {
                            items: 1,
                            nav: false,
                        },
                        // breakpoint from 768 up
                        768 : {
                            items: 2,
                        }
                    }
                });

              $('.owl-carousel.client-logo-carousel').owlCarousel({
                loop: true,
                nav: true,
                dots: false,
                navText: [
                  '<i class="blog-button fas fa-chevron-left"></i>',
                  '<i class="blog-button fas fa-chevron-right"></i>'
                ],
                items: 1
              });

                $('a[href*="#"]').on('click', function(e) {
                    e.preventDefault();
                    $('html, body').animate({
                            scrollTop: $($(this).attr('href')).offset().top,
                        },
                        250,
                        'linear'
                    )
                });
            });

            $('.owl-carousel.discover-jax-carousel').owlCarousel({
                loop: true,
                nav: true,
                // dots: false,
                navText: [
                    '<i class="blog-prev-button fas fa-chevron-left"></i>',
                    '<i class="blog-next-button fas fa-chevron-right"></i>'
                ],
                items: 3,
                autoplay: true,
                autoplayHoverPause: true,
                autoplaySpeed: 1000,
                responsive : {
                    // breakpoint from 0 up
                    0 : {
                        items: 1,
                    },
                    // breakpoint from 768 up
                    768 : {
                        items: 1,
                    },
                    // breakpoint from 922 up
                    922 : {
                        items: 2,
                    }
                }
            });

            $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
                event.preventDefault();
                event.stopPropagation();
                $(this).parent().siblings().removeClass('open');
                $(this).parent().toggleClass('open');
            });

            function eventListener() {

            }

            function mobileMenuToggle() {
                console.log('mobile menu clicked');
                $(this).toggleClass('active');
                $('#overlay').toggleClass('open');
                $('body').toggleClass('body-scroll');
                $('#primary-nav').toggleClass('menu-active');
            }

            function navigationScroll(e) {
                console.log('scrolling');
                let new_scroll_position = 0;
                let last_scroll_position;
                let header = document.getElementById("primary-nav");

                window.addEventListener('scroll', function(e) {
                    last_scroll_position = window.scrollY;

                    // Scrolling down
                    if (new_scroll_position < last_scroll_position && last_scroll_position > 280) {
                        header.classList.remove("slideDown");
                        header.classList.add("slideUp");

                        // Scrolling up
                    } else if (new_scroll_position > last_scroll_position) {
                        header.classList.remove("slideUp");
                        header.classList.add("slideDown");
                    }

                    new_scroll_position = last_scroll_position;
                });
            }

        })( jQuery );
    },
};

